<template>
  <div>
    <div
      class="content"
      style="
        padding-bottom: 25px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
      "
    >
      <div class="title">
        <div class="span"></div>
        查看权限
      </div>
      <div style="margin-left: 45px; font-size: 14px; color: #565656">
        会员历史跟进记录查看权限<el-button
          type="primary"
          size="mini"
          style="margin-left: 10px"
          @click="handleAddRole"
          >添加职位</el-button
        >
      </div>
      <div style="margin-left: 45px; margin-top: 20px; display: flex">
        <template v-for="(item, index) in client_follow_read_power_role">
          <div :key="index" style="margin-right: 33px">
            <img
              src="../../../assets/images/member/roleDel.png"
              style="
                margin-right: 10px;
                width: 20px;
                height: 20px;
                vertical-align: middle;
              "
              @click="delRole(item.id)"
            />{{ item.title }}
          </div>
        </template>
      </div>
    </div>
    <div
      class="content"
      style="
        margin-top: 10px;
        padding-bottom: 46px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
      "
    >
      <div class="title">
        <div class="span"></div>
        公海会员规则
      </div>
      <el-form>
        <div class="title" style="margin-left: 13px; font-size: 14px">
          领取会员规则<el-switch
            style="margin: 15px 0 0 23px"
            v-model="form.crm_user_take_client_state"
            :active-value="20"
            :inactive-value="10"
            active-color="#2791ff"
            inactive-color="#dcdcdc"
          />
        </div>
        <el-form-item label="员工每日可领取" style="margin-left: 45px">
          <el-radio-group
            v-model="form.user_take_client_everday"
            @change="handleUserTake"
          >
            <el-radio
              :label="0"
              :disabled="
                form.crm_user_take_client_state &&
                form.crm_user_take_client_state === 10
              "
              >不限</el-radio
            >
            <el-radio
              :label="1"
              :disabled="
                form.crm_user_take_client_state &&
                form.crm_user_take_client_state === 10
              "
              >限制
              <span
                v-if="
                  form.user_take_client_everday &&
                  form.user_take_client_everday === 1
                "
              >
                <el-input
                  :disabled="
                    form.crm_user_take_client_state &&
                    form.crm_user_take_client_state === 10
                  "
                  style="width: 90px; margin: 0 10px"
                  placeholder="请输入"
                  v-model="form.user_take_client_everday_num"
                />个会员</span
              ></el-radio
            >
          </el-radio-group></el-form-item
        >
        <div class="title" style="margin-left: 13px; font-size: 14px">
          自动回收规则<el-switch
            style="margin: 15px 0 0 23px"
            v-model="form.crm_client_recycle_state"
            :active-value="20"
            :inactive-value="10"
            active-color="#2791ff"
            inactive-color="#dcdcdc"
          />
        </div>
        <div class="box">
          <div style="font-size: 14px; color: #565656">
            当分配或者领取的会员满足以下任意勾选条件时，会在当天23:59被自动回到公海会员中
          </div>
          <div
            style="
              margin-top: 19px;
              display: flex;
              font-size: 14px;
              color: #565656;
              line-height: 45px;
            "
          >
            <el-input
              :disabled="
                form.crm_client_recycle_state &&
                form.crm_client_recycle_state === 10
              "
              style="width: 90px; margin-right: 10px"
              placeholder="请输入"
              v-model="form.recycle_follow_day"
            />天没有跟进
          </div>
          <div
            style="
              margin-top: 19px;
              display: flex;
              font-size: 14px;
              color: #565656;
              line-height: 45px;
            "
          >
            <el-input
              :disabled="
                form.crm_client_recycle_state &&
                form.crm_client_recycle_state === 10
              "
              style="width: 90px; margin-right: 10px"
              placeholder="请输入"
              v-model="form.recycle_follow_step_day"
            />天跟进阶段没有变化
          </div>
          <div
            style="
              width: 100%;
              height: 1px;
              background: #e8e8e8;
              margin: 20px 0;
            "
          ></div>
          <!-- <div style="font-size: 14px; color: #565656">回收前</div>
          <div
            style="
              margin-top: 19px;
              display: flex;
              font-size: 14px;
              color: #565656;
              line-height: 45px;
            "
          >
            <el-input
              :disabled="
                form.crm_client_recycle_state &&
                form.crm_client_recycle_state === 10
              "
              style="width: 90px; margin-right: 10px"
              placeholder="请输入"
              v-model="form.recycle_inform_day"
            />天通知跟进人
          </div> -->
        </div>
        <div style="margin: 20px 0 0 46px; color: #fd563a">
          注：即使开启自动回收，也可手动回收
        </div>
      </el-form>
    </div>
    <div style="margin: 20px auto 0 auto; text-align: center">
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
    <el-dialog
      title="添加职位"
      :visible.sync="dialogVisiable"
      :before-close="handleClose"
      :close-on-click-modal="false"
      width="30%"
    >
      <div style="">
        <el-form label-width="120px">
          <el-form-item label="职位名称">
            <el-select
              v-model="role_ids"
              placeholder="请选择职位"
              multiple
              style="width: 70%"
            >
              <template v-for="(item, index) in roleList">
                <el-option
                  :key="index"
                  :value="item.id"
                  :label="item.title"
                ></el-option
              ></template>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: right; margin-top: 10px">
        <el-button type="primary" @click="handleClose">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { roleListReq } from "@/api/authority/menage.js";
import {
  getClientSetting,
  setClientSetting,
} from "@/api/member/memberList/index.js";
export default {
  data() {
    return {
      form: {},
      dialogVisiable: false,
      roleList: [],
      role_ids: [],
      client_follow_read_power_role: [],
    };
  },
  created() {
    this.getClientSetting();
  },
  methods: {
    handleAddRole() {
      this.dialogVisiable = true;
      this.getRoleList();
    },
    /*
     * 获取职位
     */
    getRoleList() {
      roleListReq().then((res) => {
        if (res.code === 1) {
          this.roleList = res.data;
        }
      });
    },
    /*
     *  删除职位
     */
    delRole(id) {
      this.client_follow_read_power_role =
        this.client_follow_read_power_role.filter((item) => item.id !== id);
      this.role_ids = this.role_ids.filter((item) => item !== id);
    },
    handleClose() {
      this.client_follow_read_power_role = this.role_ids.map((item) => {
        let info = {
          title: "",
          id: null,
        };
        this.roleList.forEach((i) => {
          if (item === i.id) {
            info.id = i.id;
            info.title = i.title;
          }
        });
        return info;
      });
      this.dialogVisiable = false;
    },
    handleUserTake() {
      if (this.form.user_take_client_everday === 0) {
        this.form.user_take_client_everday_num = null;
      }
    },
    /*
     *  保存
     */
    submit() {
      if (
        this.form.user_take_client_everday &&
        this.form.user_take_client_everday === 0
      ) {
        this.form.user_take_client_everday_num = 0;
      }
      this.form.client_follow_read_power_role = this.role_ids;
      const data = {
        ...this.form,
      };

      delete data.user_take_client_everday;
      console.log(data);
      setClientSetting(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          this.getClientSetting();
        }
      });
    },
    getClientSetting() {
      getClientSetting().then((res) => {
        if (res.code === 1) {
          res.data.user_take_client_everday =
            res.data.user_take_client_everday_num === 0 ? 0 : 1;
          this.form = res.data;
          this.client_follow_read_power_role =
            res.data.client_follow_read_power_role;
          this.role_ids = res.data.client_follow_read_power_role.map((item) => {
            return item.id;
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .el-form-item {
  margin-bottom: 0px;
}
.box {
  padding: 20px;
  margin-left: 46px;
  width: 519px;
  background: #f9f9f9;
  border-radius: 6px;
}
</style>

